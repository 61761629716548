module.exports = {
  server: "s03 server",
  serverTokenUrl: "https://s03auth.cw.solutions/cw-identity/oauth/token",
  serverUrl: "https://s03core.cw.solutions/cw-retail/graphql",
  CWCoreServicesUrl: "https://s03core.cw.solutions/back-office-2.0/graphql",
  edcUrl: "http://localhost:80/innoweb/api/MSwipe",
  innovitiPaymentCodes: {
    Card: 0,
    GPay: 86,
    Sodexo: 90,
    UPI: 86,
  },
};
